
import {product,apiGet,_err} from '../../services/api'
import * as _ from 'lodash'
export default {
    name: "Footer",
    data(){
        return{
            productList: null,
        }
    },
    methods: {
        // eslint-disable-next-line
        gotoDetail(vals){
            // this.$router.push({ name: 'product-detail' })
            // this.$router.push('./product-detail/'+vals) 
            console.log(location,this.$router.currentRoute)
            // this.$router.push({ path: `/product-detail/${vals}`})
            // this.$router.push({name: this.$router.currentRoute.name, params: {id:vals}})
            // location.reload()
            location.href = '../../product-detail/'+vals
        }
    },
    async mounted () {
        this.productList = await apiGet(product,{
            page : 1,
            limit : 100000000,
            sort : 'ASC',
        })
        if(this.productList.length){
            this.productList  = _.uniqBy(this.productList, 'product_category_id');
        }
        if(!this.productList.length){
            _err('No data')
        }
    },
};