// import VueSweetalert2 from 'vue-sweetalert2';
import Swal from 'sweetalert2'
export const domain = 'https://apis.agc-flatglass.co.th'
const v = '/v1/frontend'
export const contact_topic = `${domain}${v}/contact-topic`
export const product = `${domain}${v}/product`
export const sendContact = `${domain}${v}/contact-form`
export const disSearch = `${domain}${v}/distributor/search`
export const disLoc = `${domain}${v}/distributor/location`

const axios = require('axios').default;
let config = {
    headers: {
        lang: 1,
    }
}
axios.defaults.headers.common['lang'] = 1
export function apiGet(path,data){
    return new Promise((reslove)=>{
        let p = null
        if(data){p = {params:data}}
        
        axios.get(`${path}`,p)
        .then(res => {
            if(res.data.header.res_code==200){
                return reslove(res.data.body) 
            }else{
                _err(res.data.header.res_desc)
                return reslove(false) 
            }
        })
        .catch(function (error) {
            // handle error
            console.log(error);
            return reslove(false) 
        })
    })
}
export function apiPost(path,data){
    return new Promise((reslove)=>{
        axios.post(`${path}`,data)
        .then(res => {
            if(res.data.header.res_code==200){
                return reslove(res.data.body) 
            }else{
                return reslove(false) 
            }
        })
        .catch(function (error) {
            // handle error
            console.log(error);
            return reslove(false) 
        })
    })
}
export function _err(text){
    Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: text,
        // footer: '<a href="">Why do I have this issue?</a>'
    })
}
export function _ok(text){
    Swal.fire(text,'success')
}